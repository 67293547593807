'use client';
import { useEffect, useState } from 'react';
import Script from 'next/script';
import { useConsentReady } from '../../../customHooks/useConsentReady';

export const Outbrain = () => {
  const consentReady = useConsentReady();
  const [loadScript, setLoadScript] = useState<boolean>(false);
  useEffect(() => {
    setLoadScript(consentReady);
  }, [consentReady]);

  return loadScript && <Script src="https://widgets.outbrain.com/outbrain.js" async />;
};

'use client';
import { useNoNav } from '../../../customHooks/useNoNav';
import Partials from '../../partials/Partials';
import type { WidgetMenuProps } from '../../partials/widget/widget-menu/widget-menu-default';
import Link from '../Link/Link';
import { Logo } from '../Logo';
import styles from './Header.module.scss';

const Header = ({ context }: { context?: Array<WidgetMenuProps> }) => {
  const noNav = useNoNav();

  if (noNav) return null;

  return (
    <header className={styles.header} data-testid="header">
      <div className={styles.top}>
        <Link href="/" className={styles.logo}>
          <Logo />
        </Link>

        {context && context.length > 0 && <Partials context={context[0]} />}
      </div>
    </header>
  );
};

export default Header;

import(/* webpackMode: "eager" */ "/srv/frontend/app/[[...segments]]/layout.module.scss");
import(/* webpackMode: "eager" */ "/srv/frontend/app/[[...segments]]/providers.tsx");
import(/* webpackMode: "eager" */ "/srv/frontend/components/lib/AdAlliance/AdAlliance.tsx");
import(/* webpackMode: "eager" */ "/srv/frontend/components/lib/AdDefend/AdDefend.tsx");
import(/* webpackMode: "eager" */ "/srv/frontend/components/lib/GoogleTagManager/GoogleTagManager.tsx");
import(/* webpackMode: "eager" */ "/srv/frontend/components/lib/MoltenBundle/MoltenBundle.tsx");
import(/* webpackMode: "eager" */ "/srv/frontend/components/lib/Outbrain/Outbrain.tsx");
import(/* webpackMode: "eager" */ "/srv/frontend/components/lib/Sourcepoint/ConsentContext.tsx");
import(/* webpackMode: "eager" */ "/srv/frontend/components/lib/Sourcepoint/Sourcepoint.tsx");
import(/* webpackMode: "eager" */ "/srv/frontend/components/UI/AdSlot/AdSlot.tsx");
import(/* webpackMode: "eager" */ "/srv/frontend/components/UI/Breadcrumb/Breadcrumb.module.scss");
import(/* webpackMode: "eager" */ "/srv/frontend/components/UI/Footer/Footer.tsx");
import(/* webpackMode: "eager" */ "/srv/frontend/components/UI/Header/Header.tsx");
import(/* webpackMode: "eager" */ "/srv/frontend/components/UI/Typography/Typography.module.scss");
import(/* webpackMode: "eager" */ "/srv/frontend/components/UI/SponsoredHeader/SponsoredHeader.module.scss");
import(/* webpackMode: "eager" */ "/srv/frontend/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/srv/frontend/utils/ErrorBoundary.tsx")